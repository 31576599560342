import React from 'react'
import classes from './consultation.module.scss'
import { motion } from 'framer-motion'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm, SubmitHandler } from 'react-hook-form'
import { sendMessage } from '../../shared/api/telegram'

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Введите имя')
    .min(2, 'Минимум 2 буквы')
    .max(10, 'Макс. 10 букв')
    .matches(/^[а-яА-Яa-zA-Z]+$/, 'Только буквы'),
  phone: yup
    .string()
    .required('Введите номер')
    .matches(
      /^\+992\d{9}$/,
      'Номер должен начинаться с (+992) и содержать 9 цифр'
    ),
})

function Consultation() {
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) })

  const onSubmit = async (data) => {
    reset()
    console.log(data)
    try {
      await sendMessage(data)
    } catch {}
  }
  const swiperAnimation = {
    hidden: { opacity: 0, scale: 0.5 },
    animate: {
      scale: 1,
      opacity: 1,
    },
  }
  return (
    <div className={classes.consultation}>
      <motion.div
        initial="hidden"
        whileInView="animate"
        variants={swiperAnimation}
        transition={{
          duration: 0.8,
        }}
        className={classes.wrapper}
      >
        <h1 className={classes.h1}>БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ</h1>
        <p className={classes.paragraph}>
          Оставьте заявку, мы с вами свяжемся и предоставим более подробную
          консультацию
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.inputIbutton}
        >
          <div className={classes.inputs}>
            <div>
              <input
                {...register('name')}
                className={classes.inputName}
                placeholder="Ваше имя*"
                type="text"
              />{' '}
              <div className={classes.error}>{errors.name?.message}</div>
            </div>
            <div>
              <input
                {...register('phone')}
                className={classes.inputPhone}
                placeholder="Номер телефона*"
                type="text"
              />{' '}
              <div className={classes.error}>{errors.phone?.message}</div>
            </div>
          </div>
          <button type="submit" className={classes.buttonSend}>
            Отправить
          </button>
        </form>
      </motion.div>
    </div>
  )
}

export default Consultation
