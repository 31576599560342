import React from 'react'
import classes from './cardResidence.module.scss'
import logo from '../img/logo/grandLogo.svg'
import logo2 from '../img/logo/logo2.svg'
import logo3 from '../img/logo/logo3.svg'
import arrow from '../img/cardArrow.svg'

function CardResidence() {
  return (
    <div className={classes.cardResidence}>
      <h1 className={classes.h1}>Наши проекты</h1>
      <div className={classes.wrapper}>
        <a href="https://grand.shohpalace.tj">
          <div className={classes.grand}>
          <div className={classes.flex}>
              <img className={classes.plazaLogo} src={logo} alt="" />
              <img src={arrow} alt="" />
            </div>            <p className={classes.paragraph}>GRAND Shoh palace</p>
          </div>
        </a>
        <a href="https://plaza.shohpalace.tj/">
          <div className={classes.grand}>
          <div className={classes.flex}>
              <img className={classes.plazaLogo} src={logo2} alt="" />
              <img src={arrow} alt="" />
            </div>            <p className={classes.paragraph}>Shoh palace PLAZA</p>
          </div>
        </a>
        <a href="https://avenue.shohpalace.tj">
          <div className={classes.grand}>
            <div className={classes.flex}>
              <img className={classes.plazaLogo} src={logo3} alt="" />
              <img src={arrow} alt="" />
            </div>
            <p className={classes.paragraph}>Shoh palace AVENUE</p>
          </div>
        </a>
      </div>
    </div>
  )
}

export default CardResidence
