import React from 'react'
import classes from './contact.module.scss'
import phone from '../img/logo/phone.svg'
import adres from '../img/logo/adres.svg'
import facebook from '../img/logo/facebook.svg'
import instagram from '../img/logo/instagram.svg'
import telegram from '../img/logo/telegram.svg'
import { motion } from 'framer-motion'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm, SubmitHandler } from 'react-hook-form'
import { sendMessage } from '../../shared/api/telegram'

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Введите имя')
    .min(2, 'Минимум 2 буквы')
    .max(10, 'Макс. 10 букв')
    .matches(/^[а-яА-Яa-zA-Z]+$/, 'Только буквы'),
  phone: yup
    .string()
    .required('Введите номер')
    .matches(
      /^\+992\d{9}$/,
      'Номер должен начинаться с (+992) и содержать 9 цифр'
    ),
})
function Contact() {
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) })

  const onSubmit = async (data) => {
    reset()
    console.log(data)
    try {
      await sendMessage(data)
    } catch {}
  }
  const topAnimation = {
    hidden: { x: -300, opacity: 0 },
    animate: {
      x: 0,
      opacity: 1,
    },
  }
  const bottomAnimation = {
    hidden: { y: 89, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
    },
  }

  const rightAnimation = {
    hidden: { x: 300, opacity: 0 },
    animate: {
      x: 0,
      opacity: 1,
    },
  }

  const swiperAnimation = {
    hidden: { opacity: 0, scale: 0.5 },
    animate: {
      scale: 1,
      opacity: 1,
    },
  }

  return (
    <div id="contact" className={classes.contact}>
      <div className={classes.wrapper}>
        <motion.h1
          initial="hidden"
          whileInView="animate"
          variants={swiperAnimation}
          transition={{
            duration: 0.5,
          }}
          className={classes.h1}
        >
          АДРЕС И КОНТАКТЫ
        </motion.h1>
        <div className={classes.adresAndMap}>
          <div>
            <motion.div
              initial="hidden"
              whileInView="animate"
              variants={topAnimation}
              transition={{
                duration: 0.5,
              }}
              id="contact"
              className={classes.contacts}
            >
              <h2 className={classes.h2}>КОНТАКТЫ</h2>
              <div className={classes.connection}>
                <div className={classes.phoneiadres}>
                  <div className={classes.phone}>
                    <img src={phone} alt="" />
                    <p className={classes.opacity}>(+992) 000 40-20-40</p>
                  </div>
                  <div className={classes.adres}>
                    <img src={adres} alt="" />
                    <p className={classes.opacity}>
                      г. Душанбе, ул. Бухоро, 45
                    </p>
                    <div className={classes.social}>
                      <div>
                        <a
                          href="https://www.facebook.com/shohpalace.plaza"
                          target="_blank"
                        >
                          <img
                            className={classes.facebook}
                            src={facebook}
                            alt=""
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.instagram.com/shohpalace_plaza/"
                          target="_blank"
                        >
                          {' '}
                          <img
                            className={classes.facebook}
                            src={instagram}
                            alt=""
                          />
                        </a>
                      </div>
                      {/* <div>
                        <a href="" target="_blank">
                          <img
                            className={classes.facebook}
                            src={telegram}
                            alt=""
                          />
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="animate"
              variants={bottomAnimation}
              viewport={{
                amount: 'some',
              }}
              transition={{
                duration: 0.5,
              }}
              id="application"
              className={classes.application}
            >
              <h2 className={classes.h2}>ОСТАВЬТЕ ЗАЯВКУ</h2>
              <p className={classes.paragraph}>
                У вас остались вопросы? Оставьте свой номер, мы вам перезвоним.{' '}
              </p>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={classes.btnInput}
              >
                <div className={classes.inputs}>
                  <div>
                    <input
                      {...register('name')}
                      className={classes.input}
                      placeholder="Ваше имя*"
                      type="text"
                    />
                    <div className={classes.error}>{errors.name?.message}</div>
                  </div>
                  <div>
                    <input
                      {...register('phone')}
                      className={classes.input}
                      placeholder="Номер телефона*"
                      type="text"
                    />
                    <div className={classes.error}>{errors.phone?.message}</div>
                  </div>
                </div>
                <div className={classes.btn}>
                  <button type="submit" className={classes.button}>
                    Отправить
                  </button>
                </div>
              </form>
            </motion.div>
          </div>
          <motion.div
            initial="hidden"
            whileInView="animate"
            variants={rightAnimation}
            transition={{
              duration: 0.5,
            }}
          >
            <iframe
              className={classes.map}
              src="https://yandex.ru/map-widget/v1/?ll=68.790756%2C38.571899&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgozMTM1OTQ1NTE3EkHQotC-0rfQuNC60LjRgdGC0L7QvSwg0JTRg9GI0LDQvdCx0LUsINC606_Rh9Cw0Lgg0JHRg9GF0L7RgNC-LCA0NSIKDd6UiUIVoEkaQg%2C%2C&sctx=ZAAAAAgBEAAaKAoSCXhEhermMVFAEX7Er1jDSUNAEhIJ6DBfXoB90T8Rwm1t4XmpwD8iBgABAgMEBSgKOABAzlBIAWISbGV0b192X2dvcm9kZT10cnVlagJ0ap0BzcxMPaABAKgBAL0Bu3Bp7MIBBq2w3Kv2AeoBAPIBAPgBAIICG9GB0LDRhNC40L3QsCDQtNGD0YjQsNC90LHQtYoCAJICBTEwMzE4mgIMZGVza3RvcC1tYXBz&sll=68.790756%2C38.571899&sspn=0.013494%2C0.006428&text=%D1%81%D0%B0%D1%84%D0%B8%D0%BD%D0%B0%20%D0%B4%D1%83%D1%88%D0%B0%D0%BD%D0%B1%D0%B5&z=16.34"
              width="560"
              height="400"
              frameborder="1"
              allowfullscreen="true"
            ></iframe>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default Contact
